import T from 'prop-types'
import React, { useRef, useCallback, useEffect } from 'react'

import { Card, Checkbox as MuiCheckbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import Table from '../../Table'
import Action from '../../atoms/Action'
import BackOfficeUsersFilterBar from './BackOfficeUsersFilterBar'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function BackOfficeUsersTable({
  company,
  role,
  backOfficeUsersByRoles,
}) {
  const companyId = company.id
  const history = useHistory()
  const { isAdmin } = useAuth()

  const tableRef = useRef()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    {
      title: t('backOfficeUsersPage.tableHeaders.lastName'),
      field: 'lastName',
    },
    {
      title: t('backOfficeUsersPage.tableHeaders.firstName'),
      field: 'firstName',
    },
    { title: t('backOfficeUsersPage.tableHeaders.email'), field: 'email' },
    {
      title: t('backOfficeUsersPage.tableHeaders.userCompanyName'),
      field: 'userCompanyName',
    },
    {
      title: t('backOfficeUsersPage.tableHeaders.subscribedToReport'),
      field: 'subscribedToReport',
      sorting: false,
      render: (item) => (
        <MuiCheckbox checked={item.subscribedToReport} disabled />
      ),
    },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [])

  const memoedFetch = useCallback(
    async (query) => {
      const backOfficeUsers = backOfficeUsersByRoles[role]
      const orderCollection = query.orderByCollection.find(
        (c) => c.sortOrder === 1
      )
      const orderBy = orderCollection?.orderByField || ''
      const orderDirection = orderCollection?.orderDirection || ''
      if (orderBy) {
        backOfficeUsers.sort((m1, m2) => {
          const comparison = m1[orderBy]
            .toLowerCase()
            .localeCompare(m2[orderBy].toLowerCase())
          if (orderDirection === 'desc') {
            return -comparison
          } else {
            return comparison
          }
        })
      }

      backOfficeUsers.forEach((row) => (row.id = row.userId))
      return {
        data: backOfficeUsers,
        page: 0,
        totalCount: backOfficeUsers.length,
      }
    },
    [backOfficeUsersByRoles, role]
  )

  const onAddClick = () => {
    return isAdmin
      ? () => history.push(`/company/${companyId}/back-office-user/${role}`)
      : null
  }

  return (
    backOfficeUsersByRoles && (
      <Card className={classes.root}>
        <BackOfficeUsersFilterBar entity={role} onAddClick={onAddClick()} />
        <Table
          style={{ boxShadow: 'none' }}
          tableRef={tableRef}
          columns={columns}
          components={{
            Action,
          }}
          title=""
          data={memoedFetch}
          options={{ paging: false }}
          actions={[
            {
              icon: '',
              text: t('common.detail'),
              onClick: (e, rowData) => {
                history.push(
                  `/company/${companyId}/back-office-user/${role}/${rowData.userId}`
                )
              },
            },
          ]}
        />
      </Card>
    )
  )
}

BackOfficeUsersTable.propTypes = {
  company: T.object.isRequired,
  role: T.string.isRequired,
  backOfficeUsersByRoles: T.object.isRequired,
}
